.dynamicDropdown {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  min-width: 250px;
  position: relative;
  margin-bottom: 24px;
  position: relative;
}

.inputPlace {
  margin-bottom: initial;
  input {
    width: 100%;
  }
}

.list {
  position: absolute;
  top: 48px;
  left: 0;
  right: 0;
  background: #ffffff;
  border-radius: 12px;
  padding: 5px;
  box-shadow: 0px 0px 1px rgba(21, 34, 66, 0.2), 0px 14px 32px rgba(21, 34, 66, 0.12);
  margin-top: 10px;
  z-index: 1;
  transform: translateY(-15px);
  opacity: 0;
  visibility: hidden;
}

.listOpened {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  transform: translateY(0);
}

.option {
  padding: 10px 5px 10px 30px;
  position: relative;
  cursor: pointer;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
  display: block;

  > &:not(:last-child) {
    border-bottom: 1px solid #eaebee;
  }
  &:hover {
    background: #f4f5f7;
  }

  &:before {
    content: '';
    width: 20px;
    height: 15px;
    position: absolute;
    top: calc(50% - 7.5px);
    left: 5px;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' standalone='no'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.0//EN' 'http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd'%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' id='body_1' width='20' height='15'%3E%3Cg transform='matrix(0.015 0 0 0.015 2.5000005 -0)'%3E%3Cg%3E%3Cg transform='matrix(0.1 0 -0 -0.1 0 253)'%3E%3Cpath d='M4214.7 2354.9C 3544.1 2159.9 2912.4001 1664.7999 2545.9001 1036.9999C 2335.4001 682.1999 2249.6 342.99988 2230.1 -202.90015C 2198.9001 -1056.8002 2315.9001 -1419.4001 3025.5 -2589.1C 3898.9 -4039.6 4101.6 -4452.9004 4382.4 -5341.9004C 4518.9 -5778.6006 4686.5 -6550.6006 4733.3 -7002.9004C 4768.4 -7299.2 4842.5 -7392.8003 5021.8 -7365.5005C 5170 -7346.0005 5259.6997 -7229.0005 5259.6997 -7061.4004C 5259.6997 -6843.0005 5411.8 -6028.1006 5536.5996 -5583.6006C 5801.6997 -4643.9004 6090.1997 -4027.9006 6831.0996 -2819.2007C 7310.6997 -2035.5007 7599.1997 -1458.4006 7700.5996 -1080.2007C 7805.8 -686.4 7798 62.2 7681 440.5C 7388.6 1395.8 6686.7 2093.7 5766.5 2347.1C 5380.6 2456.3 4581.2 2456.3 4214.7 2354.9zM5478 717.3C 5708 615.89996 5891.3 444.4 6031.6 206.5C 6125.3 50.6 6137 -7.9 6137 -339.4C 6137 -756.6 6074.6 -928.19995 5825.1 -1177.7C 5579.5 -1423.2999 5404 -1489.6 5006.3003 -1489.6C 4717.8003 -1489.6 4628.1 -1474 4491.6 -1399.9C 3969.1 -1123.1001 3719.6 -577.2 3875.6 -46.900024C 3992.6 362.49997 4234.3003 619.8 4635.9 768C 4862 849.9 5224.6 830.4 5478 717.3z' stroke='none' fill='%23737A8E' fill-rule='nonzero' /%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }
}

.errorText {
  position: absolute;
  font-size: 12px;
  line-height: 16px;
  color: #ff5c5d;
  transform: translateY(-16px);
  left: 12px;
  margin-left: 12px;
  transition: all 0.5s;
}
.showError {
  transform: translateY(6px);
  position: initial;
}
