.card {
  position: relative;
  width: 100%;
  box-shadow: 0px 0px 16px 4px rgba(217, 217, 217, 0.5);
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 16px;

  svg {
    display: none;
  }

  + .card {
    opacity: 0.5;
    svg {
      display: initial;
    }
  }
}

.imgPlanet {
  position: absolute;
  right: 0;
  top: 0;
  border-top-right-radius: 10px;
}

.title {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.0024em;
  text-transform: uppercase;
}

.title-sun {
  color: #f5b000;
}
.title-moon {
  color: #4663b7;
}
.title-venus {
  color: #b87006;
}
.title-mercury {
  color: #9059a4;
}

.description {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.0024em;
  padding: 8px 0;
}
.readMore {
  color: #4663b7;
  font-size: 14px;
  line-height: 24px;
}
.lock {
  position: absolute;
  right: 10px;
  bottom: 10px;
}
