.divider {
  position: relative;
  height: 1px;
  width: 100%;
  background: #c7c7c7;
  margin: 19px auto 19px auto;
  &:after {
    content: attr(data-divider-text);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 10px;
    background: #dee5f9;
    font-size: 14px;
  }
}
