.container {
  padding-top: 32px;
}
.wrapperTitle {
  margin-bottom: 24px;

  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.0024em;
  }
}

.inputEmail {
  margin-bottom: 24px;
  width: 100%;
  max-width: 400px;
}
.wrapperDescription {
  margin-bottom: 24px;

  h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
  }
}
.buttonNext {
  margin: 24px 0px 32px;
}
