.progressBarWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 12px 0;
  height: 62px;
}

.currentRoute {
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 2px;
  margin-bottom: 13px;

  &::first-letter {
    color: #066fde;
  }
}
.progressBar {
  width: 100%;
  background: #c2cad8;
  height: 4px;
  position: relative;
  margin-bottom: 2px;
  border-radius: 8px;

  &::after {
    content: '';
    width: 10px;
    height: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: #ffffff;
    border: 1px solid #c2cad8;
  }
}

.active {
  &::after {
    background: #066fde;
    border: 1px solid #ffffff;
  }
}

.progress {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 4px;
  background: linear-gradient(90.25deg, #066fde 0%, #c2cad8 473%);
  border-radius: 8px;
}
.buttonBack {
  position: absolute;
  left: 0;
  width: initial;
  height: initial;
  top: 12px;
}
.buttonContent {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 20px;

  > :first-child {
    margin-right: 6px;
  }
}
