.wrapper {
  display: flex;
  flex-direction: column;
  padding: 12px;
  border: 3px solid #eff2fd;
  border-radius: 8px;

  header {
    display: flex;
    margin-bottom: 8px;
    align-items: center;
  }
}
.imageAvatar {
  height: 32px;
  width: 32px;
  margin-right: 12px;
}
.wrapperUserName {
  display: flex;
  flex-direction: column;
}

.nameBlock {
  display: flex;
  align-items: center;

  > h1 {
    font-weight: 600;
    font-size: 13px;
    color: #2b7cf6;
    margin-right: 6px;
  }

  svg {
    fill: #9babd9;
    margin-top: 1px;
  }
  + div {
    margin-top: -2px;
  }
}

.review {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.1px;
  color: #000000;
}
