.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  margin-top: 32px;
}
.imageWrapper {
  margin: 24px 0px;
  display: flex;
  flex-direction: column;
  background: #eff2fd;
  width: 100%;
  max-width: 400px;
  height: 457px;
  border-radius: 8px;
  padding: 14px;
  align-items: center;
  position: relative;
}

.wrapperCorrectPalmImage {
  position: relative;
}
.checkMark {
  position: absolute;
  right: -25px;
  bottom: 32px;
}
.wrapperModal {
  position: absolute;
  top: 530px;
  width: calc(100% - 62px);
  z-index: 1;
}
.correctTitle,
.wrongTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}
.correctTitle {
  margin-bottom: 16px;
  color: #75db9c;
}
.wrongTitle {
  color: #ff5758;
  margin-top: 16px;
}
.unCorrectImages {
  display: flex;
  align-items: center;
  gap: 10px;

  & > :last-child {
    margin-left: -5px;
  }
}

.mountModal {
  position: fixed;
  background: rgba(85, 84, 85, 0.2);
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}
