.description {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.0024em;
  color: #4a567a;
  margin-top: 12px;

  b {
    font-weight: 600;
  }
}
