.switch {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 26px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #eff2fd;
  border: 1px solid #dee5f9;
  transition: 0.4s;
  border-radius: 8px;
  display: flex;
  align-items: center;

  &:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    transform: translateX(-1px);

    border-radius: 8px;
    background-color: #066fde;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
}

.input {
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + .slider {
    background-color: #eff2fd;

    &:before {
      transform: translateX(29px);
    }
  }
}
