.policy {
  display: flex;
  width: 100%;
  max-width: 400px;

  p,
  a {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #121620;
  }
  a {
    text-decoration: underline;
  }
}
