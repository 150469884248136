.paymentWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.payment {
  visibility: visible;
  opacity: 1;
  transition: all 0.5s;
}

.paymentHide {
  visibility: hidden;
  opacity: 0;
}

.loaderWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s;
  width: 100%;
  height: 135px;
}
.loaderWrapperShow {
  visibility: visible;
  opacity: 1;
}
