.experts {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  h1 {
    font-size: 20px;
    line-height: 140%;
    margin-bottom: 24px;
    text-align: center;
  }
}
.description {
  position: relative;
  display: flex;
  flex-direction: column;
  background: #066fde;
  border-radius: 8px;
  padding: 12px;
  height: 224px;
  justify-content: space-between;
  align-items: center;

  > svg {
    position: absolute;
    top: 24px;
  }
  > p {
    font-size: 14px;
    line-height: 140%;
    color: white;
  }
}
.avatars {
  display: flex;
  align-self: flex-start;

  img {
    width: 20px;
    height: 20px;
  }

  > :not(:first-child) {
    margin-left: -7px;
  }
}
