.welcome {
  position: relative;
  display: flex;
  flex-direction: column;
  background: #066fde;
  padding: 6px 0 15px 15px;
  width: 100%;
  margin-top: -8px;
}
.blockTitle {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  h1 {
    font-size: 18px;
    line-height: 21px;
    color: white;
    letter-spacing: -0.01em;
  }
}
