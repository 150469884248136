.emailHeader {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #eff2fd;
  padding: 0 32px;
  width: calc(100% + 64px);
  height: 39px;

  h1 {
    font-size: 14px;
    line-height: 140%;
    font-weight: 600;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 27px;
    width: 27px;
    border-radius: 50%;
    margin-left: 10px;
    background: #c2cad8;

    h1 {
      color: white;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 18px;
      font-weight: 600;
    }
  }
}
