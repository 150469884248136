.wrapper {
  padding-top: 32px;
}
.title {
  width: 100%;
  margin-bottom: 24px;

  h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    text-align: center;
  }
}
.womanImg {
  margin-bottom: 12px;
}
.description {
  text-align: center;
  font-size: 16px;
  line-height: 140%;
  margin: 24px 0;
}
.nextButton {
  margin-bottom: 12px;
}
.welcomePolicy {
  a {
    text-decoration: underline;
  }
}
