.reviewsWrapper {
  background: #066fde;
  position: relative;
}

.title {
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  color: white;
  margin-bottom: 24px;
}

.nextButton {
  background: white;
  color: #066fde;
  position: sticky;
  bottom: 24px;
}
.dialogWrapper {
  padding: 24px;
  display: flex;
  flex-direction: column;
  background: #fbfbfb;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  width: 311px;
  align-items: center;
  margin-bottom: 6px;

  > :last-child {
    margin-bottom: 0;
  }

  > p {
    margin-bottom: 12px;
  }
}

.date {
  margin-bottom: 24px;
  font-size: 12px;
  line-height: 18px;
  color: #eff2fd;
}

.time {
  font-size: 8px;
  line-height: 13px;
  letter-spacing: 0.07px;
  color: #8e8e93;

  em {
    font-weight: 600;
    font-style: normal;
  }
}

.buttonBack {
  background: transparent;
  border: none;
  height: 46px;
  min-height: 46px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: white;
  svg {
    fill: white;
    margin-right: 6px;
  }
}
