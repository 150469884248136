.line {
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-miterlimit: 1.5;
  stroke-dasharray: 500;
  stroke: white;
  fill: none;
  animation: move 1.5s linear;
  animation-fill-mode: forwards;
}
@keyframes move {
  to {
    stroke-dashoffset: 0;
  }
}
.life {
  stroke: #e51c39;
}
.head {
  stroke: #00d114;
  animation-delay: 1.5s;
}
.fate {
  stroke: #05ced8;
  animation-delay: 3s;
}
.heart {
  stroke: #f8d90f;
  animation-delay: 4.5s;
}
