.paymentSection {
  display: flex;
  flex-direction: column;
  background: #dee5f9;
  border-radius: 8px;
  padding: 0px 12px 12px;
  overflow: hidden;
  width: 100%;
  margin-bottom: 24px;
}
.wrapperTimer {
  width: calc(100% + 24px);
  height: 48px;
  background: #4663b7;
  margin: 0 -12px 16px;
  display: flex;
  justify-content: center;
}

.icons {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  margin: 20px 0 24px;
}
.itemIcon {
  display: flex;
  flex-direction: column;
  width: 92px;
  align-items: center;
}
.wrapperIcon {
  height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 50%;
  margin-bottom: 5px;
  svg {
    height: 52px;
    width: 52px;
  }

  @media (max-width: 350px) {
    height: 66px;
    width: 66px;
    svg {
      height: 50px;
      width: 50px;
    }
  }
}

.textIcon {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #202b47;
  text-align: center;
  padding: 0 8px;
}
.footnote {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin-top: 12px;
  width: 100%;

  b {
    font-weight: 600;
  }
}
