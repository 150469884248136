.modalError {
  background: #ffffff;
  box-shadow: 0px 0px 14px rgba(12, 31, 65, 0.24);
  border-radius: 8px;
  padding: 12px 12px 24px;
  width: calc(100% - 64px);
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 24px;
  }
  button {
    margin-bottom: 0;
  }
}
.closeButton {
  background: #eff2fd;
  border-radius: 20px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}
