.list {
  background: rgba(239, 242, 253, 0.5);
  border-radius: 8px;
  padding: 12px 17px;

  li {
    display: flex;

    svg {
      margin-top: 1px;
      margin-right: 6px;
      width: 10px;
      height: 10px;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 100%;
      flex: 1;
    }

    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }
}
