.topic {
  display: flex;
  align-items: flex-end;
  margin-bottom: 12px;
  width: 100%;
  justify-content: flex-end;
}

.topicGrey {
  flex-direction: row-reverse;
}

.message {
  background: #066fde;
  border-radius: 8px;
  padding: 12px;
  font-size: 10px;
  line-height: 16px;
  color: #ffffff;
  letter-spacing: -0.0024em;
  font-style: normal;
  font-weight: 400;
  margin-right: 8px;
}

.messageGrey {
  background: #eff2fd;
  margin-right: initial;
  margin-left: 8px;
  color: #121620;
}

.avatarWrapper {
  display: flex;
  flex: 0;
  width: 38px;
  min-width: 38px;
  height: 38px;
  min-height: 38px;
}
