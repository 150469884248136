.review {
  display: flex;
  flex-direction: column;
  border: 1px solid #dee5f9;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 12px;

  p {
    font-size: 10px;
    line-height: 140%;
  }
}
.header {
  display: flex;
  margin-bottom: 6px;

  h3 {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 2px;
    font-weight: 500;
  }
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  background: #066fde;
  text-transform: uppercase;
  color: white;
  margin-right: 6px;
  border-radius: 50%;
}
