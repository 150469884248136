.logosWrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  letter-spacing: -0.0024em;
  color: #95959d;
  width: 100%;
  margin: 24px 0px 62px;
}

.title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
}

.logos {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}
