.logos,
.footer {
  margin: 0 0 24px 0;
}
.logos {
  > :last-child {
    margin-bottom: 0;
  }
}
.nextButtonWrapper {
  margin: 24px 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.paywallWrapper {
  position: relative;
  padding-bottom: 0;

  h1 {
    font-weight: 600;
  }
}
.footerButton {
  display: flex;
  align-items: center;
  width: calc(100% + 64px);
  position: sticky;
  bottom: 0;
  padding: 12px 24px;
  gap: 12px;
  background: #eff2fd;
  box-shadow: 0px -3px 11px rgba(0, 0, 0, 0.15);
  z-index: 10;
  transition: all 0.5s;
}
.footerButtonHide {
  bottom: -90px;
}
