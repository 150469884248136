.palmPhotoSection {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 24px;
}
.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  margin-bottom: 12px;
  color: #121620;
  letter-spacing: 0.2px;
}
.wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
}
.list {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.item {
  width: 100%;
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
.option {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background: #dee5f9;
  border-radius: 32px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #4663b7;
}
