.error {
  display: flex;
  width: 100%;
  padding: 12px 18px;
  background: rgba(255, 92, 93, 0.2);
  border: 1px solid #ff5c5d;
  border-radius: 10px;
  font-size: 14px;
  line-height: 18px;
  color: #121620;
}
.icon {
  height: 20px;
  min-height: 20px;
  width: 20px;
  min-width: 20px;
  margin-right: 10px;
}
