.button {
  font-weight: 500;
  background: #eff2fd;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 12px 16px;
  max-width: 400px;
  min-width: 250px;
  width: 100%;
  min-height: 60px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 18px;
  line-height: 20px;
  color: #121620;
}

.buttonDisabled {
  cursor: not-allowed;
  background: #c7c7c7;
}

.buttonActive {
  background: #066fde;
  color: white;
}
