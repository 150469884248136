.footer {
  border-top: 1px solid #c2cad8;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 12px;
  width: calc(100% + 14px);
  margin-top: 24px;

  h4 {
    margin-bottom: 6px;
    font-size: 16px;
    line-height: 140%;
  }

  p {
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.0008em;
  }
}
.links {
  display: flex;
  margin-bottom: 8px;

  > :first-child {
    margin-right: 24px;
  }

  a {
    display: flex;
    align-items: center;
  }

  h3 {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.0008em;
    color: #0066fd;
    margin-left: 6px;
  }
}
