$width-corner: 2px;
$blue-color: #066fde;

.container {
  padding-top: 32px;
}
.palmPhotoWrapper {
  background: linear-gradient(to right, $blue-color $width-corner, transparent $width-corner) 0 0,
    linear-gradient(to right, $blue-color $width-corner, transparent $width-corner) 0 100%,
    linear-gradient(to left, $blue-color $width-corner, transparent $width-corner) 100% 0,
    linear-gradient(to left, $blue-color $width-corner, transparent $width-corner) 100% 100%,
    linear-gradient(to bottom, $blue-color $width-corner, transparent $width-corner) 0 0,
    linear-gradient(to bottom, $blue-color $width-corner, transparent $width-corner) 100% 0,
    linear-gradient(to top, $blue-color $width-corner, transparent $width-corner) 0 100%,
    linear-gradient(to top, $blue-color $width-corner, transparent $width-corner) 100% 100%;

  background-repeat: no-repeat;
  background-size: 15px 15px;
}

.title {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 32px 0 30px;
}

.wrapperBlocksInfo {
  display: flex;
  width: 100%;
  height: 155px;
  position: relative;
  justify-content: center;
  animation-name: wrapperBlocksInfo-animation;
  animation-duration: 6s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

@keyframes wrapperBlocksInfo-animation {
  0% {
    transform: translateX(0);
  }
  33%,
  66% {
    transform: translateX(calc(-100% - 24px));
  }
  100% {
    transform: translateX(calc(-200% - 48px));
  }
}

.blockInfo {
  background: #eff2fd;
  border-radius: 8px;
  padding: 16px;
  min-width: 311px;
  max-width: 560px;
  width: 100%;
  position: absolute;
  margin: 0 24px;

  + div {
    transform: translateX(calc(100% + 24px));
    + div {
      transform: translateX(calc(200% + 48px));
    }
  }
}

@keyframes blockInfo-animation {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-110%);
  }
}

.list {
  display: flex;
  flex-direction: column;
  width: 100%;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }
  }
}
