.error {
  padding: 12px 18px;
  display: flex;
  background: linear-gradient(0deg, rgba(255, 92, 93, 0.2), rgba(255, 92, 93, 0.2)), #ffffff;
  border: 1px solid #ff5c5d;
  border-radius: 10px;
  gap: 8px;

  p {
    font-size: 14px;
    line-height: 18px;
  }
}
