.container {
  position: relative;
  button {
    margin-bottom: 12px;
  }
}

.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 12px;
  animation: titleOpacity 1.5s cubic-bezier(0.37, 0, 0.63, 1);
  animation-fill-mode: forwards;
  animation-delay: 13s;
}

@keyframes titleOpacity {
  to {
    opacity: 0;
  }
}

.scanning {
  clip-path: inset(0 15px 0 0);
  animation: dots 1s steps(4) infinite;
}

@keyframes dots {
  to {
    clip-path: inset(0 -2px 0 0);
  }
}

.frameWrapper {
  position: relative;
  display: flex;
  width: 291px;
  height: 477px;
  justify-content: center;
}

.smallWrapper {
  animation: resizeWrapper 1.5s cubic-bezier(0.37, 0, 0.63, 1);
  animation-fill-mode: forwards;
}

@keyframes resizeWrapper {
  to {
    height: 207px;
  }
}

.imageWrapper {
  position: relative;
  width: 291px;
  height: 477px;
  z-index: 2;
}

.smallPhoto {
  animation: resizePhoto 1.5s cubic-bezier(0.37, 0, 0.63, 1);
  animation-fill-mode: forwards;
}

@keyframes resizePhoto {
  to {
    width: 97px;
    height: 159px;
    margin-top: 20px;
    border: 3px solid #ffffff;
  }
}
.stick {
  position: absolute;
  left: -2px;
  z-index: 5;
  width: 96px;
  height: 2px;
  background-color: #066fde;
  animation: stickMove 5.5s cubic-bezier(0.37, 0, 0.63, 1);
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-delay: 14.5s;
  opacity: 0;
}

@keyframes stickMove {
  0% {
    opacity: 1;
    top: 0%;
  }
  50% {
    opacity: 1;
    top: 100%;
  }
  100% {
    opacity: 1;
    top: 0%;
  }
}
.waitTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin-top: 70px;
  opacity: 0;

  animation: waitTitleMove 0.5s cubic-bezier(0.37, 0, 0.63, 1);
  animation-fill-mode: forwards;
  animation-delay: 14.5s;
}

@keyframes waitTitleMove {
  to {
    opacity: 1;
    margin-top: 34px;
  }
}
.description {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  animation: descriptionMove 8s cubic-bezier(0.37, 0, 0.63, 1);
  animation-fill-mode: forwards;
  animation-delay: 15s;
  margin-top: 45px;
  opacity: 0;
}

@keyframes descriptionMove {
  10% {
    opacity: 1;
    margin-top: 24px;
  }
  to {
    opacity: 1;
    margin-top: 24px;
  }
}
