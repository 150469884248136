.infoBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 12px;
  border: 2px solid #eff2fd;
  position: relative;
  margin-bottom: 24px;
}
.wrapperDecor {
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;

  > :first-child {
    position: absolute;
    left: 0;
  }

  > :last-child {
    transform: rotate(90deg);
    position: absolute;
    right: 0;
  }
}

.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  letter-spacing: 0.2px;
  color: #4663b7;
  margin-bottom: 12px;
  z-index: 1;
  text-align: center;
}
.icons {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  margin-top: auto;
}
