.svgWrapper {
  position: absolute;
}
.fingerPoint {
  animation: show 1s linear;
  animation-fill-mode: forwards;
  transform: scale(0);
  transform-origin: center center;
}

@keyframes show {
  to {
    transform: scale(1);
  }
}
.thumb {
  animation-delay: 0s;
}
.index {
  animation-delay: 1s;
}
.middle {
  animation-delay: 2s;
}
.ring {
  animation-delay: 3s;
}
.little {
  animation-delay: 4s;
}
