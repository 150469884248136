.modalWrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(85, 84, 85, 0.8);
  z-index: 2000;
}
.modal {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  max-width: 488px;
  width: 100%;
  z-index: 3;
}
