.personalPlan {
  display: flex;
  flex-direction: column;
  width: 100%;

  h1,
  h2 {
    font-weight: 600;
  }

  h1 {
    font-size: 20px;
    line-height: 140%;
    text-align: center;
  }
  h3 {
    font-size: 12px;
    line-height: 24px;
  }
}

.plan {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #eff2fd;
  border-radius: 8px;
  padding: 12px;
  gap: 12px;
  width: 100%;
  margin-top: 24px;
}

.header {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  > div {
    display: flex;
    flex-direction: column;
    margin-left: 8px;

    > h2 {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
.propertiesList {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
}
.item {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 8px;
  padding: 10px;
  position: relative;

  > svg {
    position: absolute;
    right: 6px;
  }
  h3 {
    color: #9babd9;
  }
  h2 {
    font-size: 14px;
    line-height: 140%;
    color: #2b7cf6;
  }
}
