.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
}
.wrapperIcon {
  height: 92px;
  width: 92px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 4px solid #dee5f9;
  border-radius: 50%;
  position: relative;
  background: #fff;
  margin-bottom: -46px;
  z-index: 1;
}

.block {
  width: 92px;
  height: 110px;
  display: flex;
  background: #dee5f9;
  border-radius: 0 0 4px 4px;
  align-items: flex-end;
}
.wrapperText {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 66px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #4663b7;
  padding: 0 8px;
}
