.list {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;

  li {
    display: flex;
    align-items: flex-start;

    &:not(:last-child) {
      margin-bottom: 6px;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      letter-spacing: -0.0024em;
    }
  }
}
.wrapperIcon {
  display: flex;
  align-items: center;
  height: 24px;
  margin-right: 8px;

  svg {
    fill: #066fde;
  }
}
