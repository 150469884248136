.reviews {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;

  > h1 {
    font-size: 20px;
    line-height: 140%;
    text-align: center;
  }
}
