.hands {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 24px;
  width: 100%;

  h1 {
    font-size: 20px;
    line-height: 140%;
    margin-bottom: 24px;
    text-align: center;
  }
}
.handsList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 6px;
  gap: 24px;
  border: 3px solid #eff2fd;
  border-radius: 8px;
  width: 100%;

  > :nth-child(even) {
    flex-direction: row-reverse;
  }
}

.handsItem {
  display: flex;
  align-items: center;
}
.textList {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 10px;
}
.text {
  flex: 0;
  > p {
    font-size: 14px;
    line-height: 140%;
    position: relative;

    &::before {
      content: '•';
      margin-right: 8px;
      position: absolute;
      left: -10px;
    }

    em {
      font-style: normal;
      color: #2b7cf6;
      font-weight: 600;
    }
  }
}
