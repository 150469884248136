.buttonBack {
  height: 34px;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  align-self: flex-start;
}

.buttonContent {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 20px;

  > :first-child {
    margin-right: 6px;
  }
}

.title {
  margin-bottom: 16px;
  font-size: 20px;
  line-height: 24px;
}
.imgPlanet {
  position: absolute;
  right: 0;
  top: 0;
  border-top-right-radius: 10px;
}

.fistBlock,
.secondBlock,
.thirdBlock {
  display: flex;
  flex-direction: column;
  width: calc(100% + 32px);
  margin-bottom: 16px;
}

.fistBlock,
.thirdBlock {
  background: #f5f5f5;
  padding: 24px;
  border-radius: 8px;
  margin-bottom: 24px;
}

.secondBlock {
  background: #fff9bf;
  border-radius: 10px;
  padding: 24px 16px;

  > :first-child {
    padding-bottom: 20px;
    border-bottom: 1px solid white;
    margin-bottom: 20px;
  }

  li {
    display: flex;
    font-size: 16px;
    line-height: 22px;

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    &::before {
      content: '•';
      margin-right: 8px;
    }
  }
}

.headerBlock {
  display: flex;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.24px;
  margin-bottom: 8px;

  svg {
    margin-right: 8px;
    fill: #ffa800;
  }
}

.fistBlock {
  position: relative;
  h1 {
    letter-spacing: -0.0024em;
    font-size: 16px;
    line-height: 24px;
    color: #f5b000;
    text-transform: uppercase;
    margin-bottom: 16px;
  }
  h4 {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.0024em;
    margin-bottom: 16px;
  }
}
.nameBlock {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  + .nameBlock {
    margin-bottom: 0px;
  }
}
.name {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.0024em;
  margin-bottom: 6px;

  div {
    background: #fff9bf;
    border-radius: 20px;
    padding: 0px 10px;
    color: #f5b000;
    margin-left: 8px;
  }
}
.thirdBlock {
  h1 {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.24px;
    margin-bottom: 16px;

    + h1 {
      color: #ffa800;
      margin-bottom: 6px;
      line-height: 24px;
    }
  }
  h4 {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 6px;
  }
  p {
    font-size: 12px;
    line-height: 16px;
  }
}
