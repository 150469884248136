.selectSubscriptionWrapper {
  h1 {
    font-weight: 500;
  }
}
.cometImg {
  margin: 12px 0;

  + div + h1 {
    margin-bottom: 12px;
  }
}
