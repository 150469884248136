.formWrapper {
  margin: 0 16px;
  min-width: 290px;
  position: relative;
  background: white;
  border-radius: 16px;
  padding: 64px 20px 32px;
  box-sizing: border-box;
  z-index: 5;
}

.form {
  position: relative;

  visibility: hidden;
}

.inputsForm {
  position: relative;
  z-index: 2;
  background-color: white;
  width: 100%;
}

.formBlocked {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  cursor: not-allowed;
  background-color: #121620;
  z-index: 8;
  border-radius: 16px;
}
.formShow {
  visibility: visible;
}

.buttonClose {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 16px;
  right: 20px;
  border: none;
  background: none;
  box-sizing: border-box;
  padding: 0;
  background: #eff2fd;
  border-radius: 20px;
}

.inputNameOnCard {
  border: 1px solid rgb(199, 199, 199);
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 12px;

  input {
    color: rgb(18, 22, 32);
    width: 100%;
    border: none;
    height: 46px;
    outline: none;
    padding: 14px 12px;
    font-size: 15px;
    font-weight: 400;
  }
}
.cardNumberWrapper {
  position: relative;
  margin-bottom: 12px;
  transition: all 0.5s;
}
.cardNumberWrapperShowError {
  margin-bottom: 24px;
}
.cardNumberInput {
  position: relative;
  background-color: white;
  z-index: 7;
  height: 48px;
}
.cardIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
}
.frameInput {
  font-family: 'SF Pro Text Regular, sans-serif';
  height: 48px;
  box-sizing: border-box;
  display: block;
}

.errorCard {
  position: absolute;
  font-family: 'SF Pro Text Regular, sans-serif';
  font-size: 12px;
  line-height: 16px;
  color: #ff5758;
  transform: translateY(-18px);
  z-index: 6;
  transition: all 0.5s;
}

.showErrorCard {
  transform: translateY(2px);
}

.dateCvvCodeWrapper {
  display: flex;

  & > :first-child {
    margin-right: 12px;
  }
  margin-bottom: 12px;
}

.checkoutButton {
  width: 100%;
  max-width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  border: none;
  margin-top: 16px;

  svg {
    margin-right: 12px;
    fill: white;
  }
}
.checkoutButtonDisable {
  svg {
    fill: #121620;
  }
}
.wrapperError {
  z-index: 1;
  transform: translateY(50%);
  opacity: 0;
  height: 0px;
  transition: all 0.5s;
}
.showError {
  height: initial;
  transform: translateY(0%);
  margin-bottom: 12px;
  transition: all 0.5s;
  opacity: 1;
}
