.wrapper {
  display: flex;
  width: 210px;
  height: 210px;
  position: relative;
  align-items: center;
  justify-content: center;

  &::after {
    content: '';
    width: inherit;
    height: inherit;
    border: 2px solid #eff2fd;
    position: absolute;
    border-radius: 50%;
  }
}

.lightBlueCircle {
  width: 200px;
  height: 200px;
  background: #eff2fd;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.imageWrapper {
  width: 113px;
  height: 157px;
  border: 3px solid #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.image {
  width: inherit;
  height: inherit;
  object-fit: cover;
}
