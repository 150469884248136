.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  margin-top: 24px;
}

.description {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  margin: 24px 0;
}

.inputPlace {
  margin-bottom: 24px;
}

.customInputSelector {
  margin-bottom: initial;
  width: 100%;

  p {
    color: #6b7baa;
  }

  input {
    background: #eff2fd;
    border: 2px solid #dee5f9;
    padding-top: 5px;

    &:not(:placeholder-shown) + p,
    &:focus + p {
      display: none;
    }
  }
}
.customList {
  border: 2px solid #dee5f9;
  box-shadow: 0px 4px 4px rgba(107, 118, 170, 0.15);
  border-radius: 8px;
  padding: 0px;

  li {
    padding-left: 12px;
    font-size: 14px;
    line-height: 100%;
    color: #6b7baa;

    &::before {
      display: none;
    }
  }
}
.inputError {
  input {
    color: #ff5c5d;
  }
}
