.wrapperCamera {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}

.buttonShutter {
  position: absolute;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: #fff;
  transform: translateX(-50%);
  left: 50%;
  bottom: 30px;
  outline: 20px solid rgba(255, 255, 255, 0.28);
}
