.moneyBack {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  background: #eff2fd;
  padding: 16px 12px;
  margin-bottom: 24px;

  > svg {
    margin-bottom: 12px;
  }

  h1 {
    text-align: center;
    color: #2b7cf6;
    font-size: 20px;
    line-height: 130%;
    margin-bottom: 12px;
  }

  p {
    font-size: 14px;
    line-height: 140%;
  }
}
