.menu {
  width: 100%;
  border-radius: 8px;
  background: white;
  filter: drop-shadow(0px 0px 14px rgba(12, 31, 65, 0.24));
  padding: 0px 10px;

  li {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 5px;

    &:not(:last-child) {
      border-bottom: 1px solid #c7c7c7;
    }
    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }
}
