.analyzingWrapper {
  background: #066fde;
  height: 100%;
  padding-top: 32px;
}
.starsWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.animate {
  position: absolute;
}
.list {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 12px;

  li {
    display: flex;
    align-items: baseline;
  }

  h3 {
    font-size: 20px;
    line-height: 140%;
    color: white;
    flex: 1;
  }
}

.iconCheck {
  height: 15px;
  width: 15px;
  fill: transparent;
  margin-right: 12px;
}
.iconCheckShow {
  fill: white;
  transition: all 0.5s;
}
