.reportWrapper {
  padding-top: 32px;

  h1 {
    font-weight: 600;
  }
}
.title {
  font-size: 20px;
  line-height: 140%;
  text-align: center;
}

.marksWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 12px 0 32px;

  > :first-child {
    margin-right: 16px;
  }
}

.mark {
  display: flex;
  align-items: center;
  background: #eff2fd;
  border-radius: 100px;
  padding: 6px 24px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #8e8e93;
  letter-spacing: -0.0024em;
  svg {
    margin-right: 8px;
  }
}

.activeMark {
  background: #066fde;
  font-weight: 600;
  color: white;
}

.compatibility {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-bottom: 32px;
  padding: 10px 0px;
}
.percent {
  width: 100%;
}
.avatarWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.0024em;
    margin-top: 16px;
  }
}
.preview {
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  margin-top: 12px;
}
.cards {
  > :last-child {
    margin-bottom: 12px;
  }
}
