.partnerWrapper {
  background: #066fde;
  height: 100%;
}

.buttonBack {
  background: transparent;
  border: none;
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  color: white;
  svg {
    fill: white;
    margin-right: 6px;
  }
}
.text {
  font-size: 20px;
  line-height: 140%;
  color: white;
  margin: 24px 0;
  text-align: center;
}
.nextButton {
  background: white;
  color: #066fde;
  height: 56px;
  min-height: 56px;
}
