.loading {
  animation: 1s linear 0s normal none infinite running rot;
}
@keyframes rot {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.check {
  width: 18px;
  height: 18px;
  background: #066fde;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
