.buttonWrapper {
  width: 100%;
  justify-content: center;
  display: flex;

  > :not(:last-child) {
    margin-right: 12px;
  }
}

.title {
  font-size: 20px;
  font-weight: 500;
  line-height: 140%;
  text-align: center;
  margin: 24px 0;
}
