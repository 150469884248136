.employeeName {
  font-size: 8px;
  line-height: 13px;
  letter-spacing: 0.07px;
  color: #8e8e93;
  margin-bottom: 6px;
}
.customTopic {
  > :first-child {
    color: transparent;
    text-shadow: 0 0 4px rgb(255, 255, 255);
  }
}
