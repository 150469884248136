.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  margin: 24px 0;
}

.customButton {
  margin-bottom: 12px;
  border: 2px solid #066fde;
  color: #0066fd;
  height: 56px;
  background-color: white;
  padding: 0 24px;
}

.activeButton,
.customButton:active {
  background-color: #066fde;
  color: white;
}
