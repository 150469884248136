.wrapperProgressBar {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
}
.progressBar {
  position: absolute;
  background: #dee5f9;
  border-radius: 8px;
  height: 4px;
  width: 100%;
}

.progress {
  position: absolute;
  height: 6px;
  background: #066fde;
  border-radius: 8px;
  z-index: 2;
  animation: progress-animation 10s linear 0s 1;
  animation-fill-mode: forwards;
}

@keyframes progress-animation {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

.wrapperSerifs {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: -4px;
  z-index: 4;

  & :nth-child(1) {
    animation-delay: 0s;
  }
  & :nth-child(2) {
    animation-delay: 3s;
  }
  & :nth-child(3) {
    animation-delay: 6.5s;
  }
  & :nth-child(4) {
    animation-delay: 9.7s;
  }
}

.serif {
  height: 15px;
  width: 2px;
  background-color: #c2cad8;
  animation-name: serif-animation;
  animation-duration: 300ms;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

@keyframes serif-animation {
  to {
    background-color: #066fde;
  }
}

.itemsProgressBar {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 14px;
  z-index: 3;
  > :nth-child(1) p {
    animation-delay: 1s;
  }
  > :nth-child(2) p {
    animation-delay: 4s;
  }
  > :nth-child(3) p {
    animation-delay: 8s;
  }
}
.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 60px;

  p {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #c2cad8;
    letter-spacing: 0.0008em;
    animation-name: item-animation;
    animation-fill-mode: forwards;
    animation-duration: 1s;
  }
}

@keyframes item-animation {
  to {
    color: #066fde;
  }
}
