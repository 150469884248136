.header {
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: center;
  background: #eff2fd;
  min-height: 50px;
  position: relative;
}
.buttonPosition {
  position: absolute;
  left: 28px;
}

.avatar {
  height: 27px;
  width: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(134, 152, 204, 0.7);
  border-radius: 50%;
  color: #ffffff;
}
