.finalWrapper {
  position: relative;
}

.customNextButton {
  position: sticky;
  bottom: 24px;
}

.buttonBack {
  width: 100%;
  justify-content: flex-start;
  height: 28px;
  margin-bottom: 24px;
  padding-top: 6px;
}

.buttonContent {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 20px;

  > :first-child {
    margin-right: 6px;
  }
}

.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  margin-bottom: 12px;
}
.secondTitle {
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  margin-bottom: 12px;
  text-decoration: underline;
}

.descriptionBlock {
  display: flex;
  flex-direction: column;
  background: rgba(239, 242, 253, 0.5);
  border-radius: 8px;
  padding: 12px;

  h1 {
    font-size: 18px;
    line-height: 120%;
    text-align: center;
    color: #0066fd;
    margin-bottom: 12px;
  }

  li {
    display: flex;
    align-items: baseline;

    &:not(:last-child) {
      margin-bottom: 6px;
    }

    p {
      flex: 1;
      margin-left: 6px;
      font-size: 16px;
      line-height: 140%;
    }
  }
}
.reviewsSection {
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 20px;
    line-height: 140%;
    margin-bottom: 12px;
    text-align: center;
  }
}
.customLogos {
  margin-bottom: 0px;
  h4 {
    margin-bottom: 12px;
  }
}

footer {
  border-top: 1px solid #c2cad8;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 12px;
  width: calc(100% + 14px);
  margin-top: 24px;

  h4 {
    margin-bottom: 6px;
    font-size: 16px;
    line-height: 140%;
  }

  p {
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.0008em;
  }
}
.links {
  display: flex;
  margin-bottom: 8px;

  > :first-child {
    margin-right: 24px;
  }

  a {
    display: flex;
    align-items: center;
  }

  h3 {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.0008em;
    color: #0066fd;
    margin-left: 6px;
  }
}
.imageWrapper {
  width: 375px;
  height: 350px;
}
