.buttonShowCard {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  background: rgb(6, 111, 222);
  border: none;
  cursor: pointer;
  outline: none;
  padding: 18px;
  position: relative;
  min-width: 150px;
  color: white;

  > :first-child {
    margin-right: 8px;
  }

  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
  }
}
