.buttonBack {
  width: 100%;
  justify-content: flex-start;
  height: 28px;
  margin-bottom: 24px;
  padding-top: 6px;
}

.buttonContent {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 20px;

  > :first-child {
    margin-right: 6px;
  }
}
.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
}

.inputEmail {
  position: relative;
  z-index: 3;
  input {
    height: 56px;
  }
}

.img {
  margin: 24px 0;
}
.policy {
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.0008em;
  margin-top: 24px;
}
.formEmail {
  width: 100%;
  max-width: 400px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
  transition: all 0.3s;
}
.formEmailError {
  margin-bottom: 62px;
  h3 {
    transform: translateY(62px);
  }
  transition: all 0.3s;
}
.error {
  position: absolute;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.0008em;
  color: #ff5c5d;
  left: 24px;
  transform: translateY(22px);
  z-index: 2;
  transition: all 0.3s;
}
