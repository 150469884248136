.wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  h3,
  p {
    text-align: center;
    line-height: 48px;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
  }
}
.timer {
  width: 50px;
  height: 100%;
  position: relative;
  p {
    position: absolute;
    font-variant-numeric: tabular-nums;
    display: inline-block;
    position: absolute;
    left: 5px;
    transform: translateY(-50%);
    top: 50%;
  }
}
