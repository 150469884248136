.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  margin-top: 24px;
}

.description {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  margin: 24px 0;
}

.formTime {
  position: relative;
  margin-bottom: 24px;
}

.inputWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 56px;
  gap: 12px;
  width: 100%;
  max-width: 400px;
  background-color: white;
  position: relative;
  z-index: 3;
}
.inputTime {
  input {
    min-width: 96px;
  }
}

.inputError {
  input {
    color: #ff5c5d;
  }
}

.switchWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.switchLabel {
  display: flex;
  justify-content: center;
  margin-bottom: 4px;
  color: #dee5f9;

  > :first-child {
    margin-right: 8px;
  }

  h1 {
    font-size: 14px;
    line-height: 140%;
  }
}
.activeLabel {
  color: #0066fd;
}

.skipButton {
  margin-top: 24px;
  border: 2px solid #066fde;
  color: #0066fd;
  height: 56px;
  background-color: white;
}

.errorText {
  position: absolute;
  font-size: 12px;
  line-height: 16px;
  color: #ff5c5d;
  transform: translateY(-16px);
  left: 12px;
  margin-left: 12px;
  transition: all 0.5s;
}
.showError {
  transform: translateY(6px);
  position: initial;
}
