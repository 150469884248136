.wrapper {
  display: flex;
  flex-direction: column;
  padding: 12px;
  border: 1px solid #eff2fd;
  border-radius: 4px;
  margin-bottom: 12px;

  header {
    display: flex;
    margin-bottom: 8px;
  }
}
.imageAvatar {
  height: 32px;
  width: 32px;
  margin-right: 12px;
}
.wrapperUserName {
  display: flex;
  flex-direction: column;
}
.nameBlock,
.recommendsBlock {
  display: flex;
  align-items: center;
  height: 18px;
}
.nameBlock {
  display: flex;
  align-items: center;

  h3 {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.1px;
    color: #44588e;
    margin-right: 6px;
  }
}
.recommendsBlock {
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 0.07px;

  p {
    color: #8e8e93;
    margin: 0 2px 0 6px;
  }
  em {
    color: #44588e;
  }
}

.review {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.1px;
  color: #000000;
}
