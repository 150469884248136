.zodiac {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;

  > h1 {
    font-size: 20px;
    line-height: 140%;
    margin-bottom: 24px;
  }

  > p {
    font-size: 14px;
    line-height: 140%;
    margin-bottom: 24px;
  }
}
.info {
  background: #eff2fd;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 12px;
  width: 100%;
  position: relative;
}
.plus {
  position: absolute;
  font-size: 30px;
  line-height: 24px;
  top: 55px;
}
.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1;

  > svg {
    margin-bottom: 16px;
  }

  > h2 {
    font-size: 16px;
    line-height: 24px;
  }

  > h3 {
    font-size: 12px;
    line-height: 24px;
    margin-bottom: 22px;
  }
}

.propertiesList {
  width: 100%;
  flex-basis: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
}
.itemProp {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 8px;
  padding: 10px;
  gap: 6px;

  > h3,
  > p {
    font-size: 14px;
    line-height: 140%;
    font-weight: 600;
  }
  > p {
    color: #2b7cf6;
  }
}

.progress {
  position: relative;
  height: 6px;
  width: 100%;
  background: #dee5f9;
  border-radius: 12px;

  > div {
    position: absolute;
    height: 6px;
    background: #2b7cf6;
    border-radius: 12px;
    z-index: 1;
  }
}
