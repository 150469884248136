.customInput {
  margin-bottom: initial;
  width: 100%;

  p {
    color: #6b7baa;
  }

  input {
    background: #eff2fd;
    border: 2px solid #dee5f9;
    padding-top: 5px;

    &:not(:placeholder-shown) + p,
    &:focus + p {
      display: none;
    }
  }
}
.customInputError {
  input {
    color: #ff5c5d;
  }
}
