.paywallWrapper {
  padding-bottom: 20px;
}
.buttonBack {
  width: 100%;
  justify-content: flex-start;
  height: 28px;
  margin-bottom: 24px;
  padding-top: 6px;
}

.buttonContent {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 20px;

  > :first-child {
    margin-right: 6px;
  }
}
.womanImg {
  margin: 24px 0px;
}
