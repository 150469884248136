.discountWrapper {
  h1 {
    font-weight: 600;
  }
}

.title {
  font-size: 20px;
  line-height: 120%;
  text-align: center;
  margin-bottom: 24px;
  margin-top: 23px;
}

.blocksWrapper {
  display: flex;
  align-items: flex-end;
  gap: 7px;
}

.leftBlock,
.rightBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #c7c7c7;
  border-radius: 10px;
  text-align: center;
  width: 160px;
  height: 258px;
  padding: 16px;
  overflow: hidden;

  h1 {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.0024em;
    margin-bottom: 12px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    text-align: center;
    letter-spacing: 0.0008em;
    color: #8e8e93;
  }

  button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 132px;
    height: 56px;
    background: #066fde;
    border-radius: 10px;
    border: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: white;
    font-weight: 600;
  }
}

.rightBlock {
  height: 290px;
  border: 2px solid #066fde;
  padding-top: 0px;
}
.headerBlock {
  top: 0;
  height: 32px;
  width: calc(100% + 32px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: #066fde;
  margin-bottom: 16px;
}
