.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  margin: 24px 0;
}
.zodiacs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 294px;
  gap: 12px;
}
.zodiacButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
  border: none;
  padding: 0;

  h3 {
    font-size: 14px;
    line-height: 18px;
    text-transform: capitalize;
  }
}
.imgWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 76px;
  border: 1px solid #066fde;
  border-radius: 8px;
  margin-bottom: 6px;
  svg {
    fill: #066fde;
  }
}

.activeButton,
.zodiacButton:active {
  .imgWrapper {
    background: #066fde;
    svg {
      fill: white;
    }
  }
}
