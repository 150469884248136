.subscription {
  display: flex;
  flex-direction: column;
  padding: 12px;
  width: 100%;
  color: #121620;
  background: #ffffff;
  border: 2px solid #007aff;
  border-radius: 8px;
  font-size: 12px;
}
.firstBlock {
  display: flex;
  border-bottom: 1px solid #8e8e93;
  padding-bottom: 8px;
  margin-bottom: 8px;
  align-items: center;
  justify-content: space-between;
  h1 {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
  }
}
.secondBlock {
  display: flex;
  justify-content: space-between;
  color: #8e8e93;
  font-size: 11px;
  line-height: 16px;
  font-weight: 400;
}
.code {
  font-weight: 500;
  color: #04a777;
  line-height: 18px;
}

.pricesCount {
  display: flex;
  line-height: 18px;

  > :first-child {
    text-decoration: line-through;
    margin-right: 5px;
  }
}
.discount {
  font-weight: 600;
}
