.button {
  height: 134px;
  width: 95px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #066fde;
  border-radius: 8px;
  background-color: white;

  h3 {
    font-size: 16px;
    line-height: 20px;
    color: #066fde;
    margin-bottom: 10px;
    text-transform: capitalize;
  }

  svg {
    fill: #066fde;
  }
}

.activeButton,
.button:active {
  background-color: #066fde;

  svg {
    fill: white;
  }

  h3 {
    color: white;
  }
}
