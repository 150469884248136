.cardHeader {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #c7c7c7;
  flex-direction: column;
  margin-bottom: 16px;
  h3 {
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.0024em;
    font-size: 18px;
  }
}

.cardList {
  width: 213px;
  margin: 12px auto;
  display: flex;
  justify-content: space-between;

  .cardList-0-2-167 img {
    height: 25px;
  }
}
