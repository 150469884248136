.container {
  position: relative;
  button {
    margin-bottom: 12px;
  }
}
.containerNotActive {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(16, 32, 77, 0.35);
  z-index: 1;
}
