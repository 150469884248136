.header {
  display: flex;
  width: 100%;
  height: 56px;
  min-height: 56px;
  align-items: center;
  justify-content: center;
  background: #fbfbfb;
  border-bottom: 1px solid #dee5f9;
}
