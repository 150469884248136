.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  margin-top: 24px;
}

.description {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  margin: 24px 0;
}

.inputWrapper {
  position: relative;
  z-index: 3;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  width: 100%;
  max-width: 400px;
  background-color: white;
}

.formBirthday {
  position: relative;
  margin-bottom: 24px;
}

.inputItem {
  h3 {
    font-size: 12px;
    line-height: 16px;
    color: #6b7baa;
    margin: 0 0 6px 6px;
  }

  input {
    min-width: 96px;
  }
}
.errorText {
  position: absolute;
  font-size: 12px;
  line-height: 16px;
  color: #ff5c5d;
  transform: translateY(-32px);
  left: 12px;
  margin-left: 12px;
  transition: all 0.5s;
}
.showError {
  transform: translateY(6px);
  position: initial;
}
