.customField {
  position: relative;
  font-size: 16px;
  margin-bottom: 20px;
  display: block;
  height: 48px;

  input {
    appearance: none;
    padding: 5px 12px;
    padding-top: 12px;
    border-radius: 8px;
    max-width: 400px;
    min-width: 250px;
    width: 100%;

    height: 48px;
    outline: none;
    border: 1px solid #c7c7c7;
    color: #121620;
    font-size: 16px;
    line-height: 18px;
    transition: border-color 0.3s ease;

    &:not(:placeholder-shown) + .placeholder,
    &:focus + .placeholder {
      top: 12px;
      font-size: 12px;
      width: auto;
    }
    &:focus {
      border-color: #066fde;
      transition-delay: 0.1s;
    }
  }
}

.error {
  input {
    border-color: #ff5758;
  }
}
.errorText {
  font-size: 14px;
  line-height: 18px;
  color: #ff5758;
  position: absolute;
  left: 12px;
}

.placeholder {
  position: absolute;
  left: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  top: 50%;
  font-size: 16px;
  transform: translateY(-50%);
  color: #8e8e93;
  transition: top 0.3s ease, color 0.3s ease, font-size 0.3s ease;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  right: 2px;
  transform: translateY(-50%);
  position: absolute;
  width: 30px;
  height: 30px;
  background: transparent;
}

.buttonClear {
  background: none;
  border: none;
  padding: 0;
  display: flex;
  cursor: pointer;
}
.spinner {
  width: 14px;
  height: 14px;
  top: initial;
  left: initial;
  margin: 0;
}
