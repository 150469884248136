$width-corner: 2px;
$blue-color: #066fde;

.wrapper {
  display: flex;
  width: 220px;
  height: 220px;
  position: absolute;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
  animation: wrapperOpacity 1.5s cubic-bezier(0.37, 0, 0.63, 1);
  animation-fill-mode: forwards;
  animation-delay: 12s;
  opacity: 0;
}

.corners {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  background: linear-gradient(to right, $blue-color $width-corner, transparent $width-corner) 0 0,
    linear-gradient(to right, $blue-color $width-corner, transparent $width-corner) 0 100%,
    linear-gradient(to left, $blue-color $width-corner, transparent $width-corner) 100% 0,
    linear-gradient(to left, $blue-color $width-corner, transparent $width-corner) 100% 100%,
    linear-gradient(to bottom, $blue-color $width-corner, transparent $width-corner) 0 0,
    linear-gradient(to bottom, $blue-color $width-corner, transparent $width-corner) 100% 0,
    linear-gradient(to top, $blue-color $width-corner, transparent $width-corner) 0 100%,
    linear-gradient(to top, $blue-color $width-corner, transparent $width-corner) 100% 100%;

  background-repeat: no-repeat;
  background-size: 15px 15px;

  animation: cornersOpacity 1.5s cubic-bezier(0.37, 0, 0.63, 1);
  animation-fill-mode: forwards;
  animation-delay: 13.5s;
  opacity: 0;
}

@keyframes cornersOpacity {
  10% {
    opacity: 0;
  }
  20% {
    opacity: 0.7;
  }
  40% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

@keyframes wrapperOpacity {
  to {
    opacity: 1;
  }
}

.lightBlueCircle {
  width: 200px;
  height: 200px;
  background: #eff2fd;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}
