.buttonBack {
  width: 100%;
  justify-content: flex-start;
  height: 28px;
  margin-bottom: 24px;
  padding-top: 6px;
}

.buttonContent {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 20px;

  > :first-child {
    margin-right: 6px;
  }
}

.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  text-align: center;
}

.plans {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 27px;

  > :not(:last-child) {
    margin-right: 12px;
  }

  > :last-child {
    position: relative;

    &::after {
      position: absolute;
      content: '';
      width: 2px;
      height: 15px;
      background: #c2cad8;
      bottom: -24px;
    }
  }
}

.plan {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 68px;
  height: 56px;
  border: 2px solid #c2cad8;
  border-radius: 8px;

  h3 {
    font-size: 16px;
    line-height: 20px;
  }
}

.activePlan {
  border: 2px solid #066fde;
  color: #066fde;

  &::after {
    background: #066fde !important;
  }
}
.subscriptionText {
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  margin-bottom: 16px;
  font-weight: 600;
}
.blueColorText {
  color: #0066fd;
}
.button {
  background: #066fde;
  color: white;
  min-height: 56px;
  position: sticky;
  bottom: 24px;

  svg {
    margin-right: 12px;
  }
}
