.contentContainer {
  padding-top: 8px;
}

.progressContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.progressPercentage {
  font-family: 'SF Mono Bold, sans-serif';
  position: absolute;
  font-size: 36px;
  line-height: 140%;
  font-variant-numeric: tabular-nums;
  color: white;
}

.progress {
  display: block;
  width: 164px;
  height: 164px;
  transform: rotateZ(-90deg);
}

.progressBar,
.progressValue {
  fill: none;
  stroke-width: 12;
  cx: 99.5;
  cy: 99.5;
}

.progressBar {
  stroke: #005bbb;
}

.progressValue {
  stroke: white;
  stroke-linecap: round;
}
.percentWrapper {
  display: flex;
  width: 200px;
  height: 200px;
}
