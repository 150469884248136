.container {
  padding-top: 32px;
}
.title {
  margin-top: 24px;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 24px 0px 12px;

  h2,
  h4 {
    text-align: center;
    margin-bottom: 24px;
  }
  h2 {
    line-height: 150%;
    letter-spacing: 0.2px;
  }
  h4 {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }
}

.policyWrapper {
  margin-top: 8px;
}

@media (max-width: 350px) {
  .content {
    h2 {
      font-size: 18px;
    }
    h3 {
      font-size: 16px;
    }
  }
}
