.modal {
  max-width: 560px;
  width: 100%;
}
.content {
  background: white;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding: 24px 20px;
  align-items: center;
  margin: 0 16px;

  h1 {
    font-size: 24px;
    line-height: 120%;
    margin-bottom: 12px;
  }

  p {
    font-size: 18px;
    line-height: 120%;
    margin: 12px 0;
    text-align: center;
  }
}
