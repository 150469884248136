.payment {
  display: flex;
  flex-direction: column;
  padding: 12px;
  background: #eff2fd;
  align-items: center;
  width: 100%;
  border-radius: 0 0 8px 8px;
  margin-bottom: 24px;

  > h1 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 12px;
  }
  > p {
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    margin-top: 6px;
  }
}
.users {
  display: flex;
  padding: 10px;
  width: 100%;

  > :not(:first-child, p) {
    margin-left: -7px;
  }

  p {
    font-size: 12px;
    line-height: 16px;
    margin-left: 8px;
    color: #4a567a;

    // a {
    //   display: none;
    //   color: #4a567a;
    //   &:visited {
    //     color: #4a567a;
    //   }
    // }
  }

  // a {
  //   display: none;

  //   color: #4a567a;
  //   &:visited {
  //     color: #4a567a;
  //   }
  // }

  .avatar {
    width: 20px;
    height: 20px;
  }
}
.nextButton {
  margin: 12px 0;
}
