.header {
  margin-bottom: 24px;
}
.title {
  margin-bottom: 24px;
  text-align: center;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
}
.item {
  display: flex;
  align-items: flex-start;
  align-self: flex-start;
  margin-bottom: 10px;

  p {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
  }

  & > :first-child {
    margin-right: 7px;
  }

  + button {
    margin-top: 10px;
  }
}
.imageWrapper {
  margin: 14px 0px 24px;
  width: 239px;
  height: 168px;
}
