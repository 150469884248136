.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  margin: 24px 0;
}

.buttonWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
}

.customButton {
  width: 142px;
  min-width: 142px;
}
