.buttonBack {
  height: 28px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  align-self: flex-start;
}

.buttonContent {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 20px;

  > :first-child {
    margin-right: 6px;
  }
}

.title {
  margin-bottom: 6px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;

  + h4 {
    font-size: 16px;
    line-height: 140%;
    margin-bottom: 24px;
    font-weight: 600;
  }
}

.offer {
  border: 1px solid #04a777;
  border-radius: 8px;
  padding: 10px 16px;
  margin-bottom: 24px;

  h1 {
    font-size: 14px;
    line-height: 140%;
    color: #04a777;
    font-weight: 600;
  }
}

.todayCount,
.weekCount {
  display: flex;
  border-top: 1px solid #c2cad8;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  * {
    font-weight: 600;
  }
}

.todayCount {
  height: 46px;

  h1 {
    font-size: 14px;
    line-height: 120%;

    + h1 {
      color: #0066fd;
    }
  }
}

.weekCount {
  border-bottom: 1px solid #c2cad8;
  margin-bottom: 24px;
  padding: 12px 0px;

  h4 {
    font-size: 14px;
    line-height: 18px;
    color: #6b7baa;
  }
}

.leftBlock {
  display: flex;
  flex-direction: column;
  > :nth-child(1) {
    color: #04a777;
  }
  > :nth-child(2) {
    color: #121620;
    font-weight: 500;
  }
}

.rightBlock {
  display: flex;
  gap: 17px;

  > :nth-child(1) {
    text-decoration: line-through;
  }
  > :nth-child(2) {
    color: #121620;
  }
}

.description {
  font-size: 12px;
  line-height: 17px;
  letter-spacing: -0.0024em;
  margin-bottom: 24px;
}

.paymentWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.payment {
  visibility: visible;
  opacity: 1;
  transition: all 0.5s;
}

.paymentHide {
  visibility: hidden;
  opacity: 0;
}

.loaderWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s;
  width: 100%;
  height: 135px;
}
.loaderWrapperShow {
  visibility: visible;
  opacity: 1;
}
.divider {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.paymentButton {
  height: 56px;
}
