.form {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 24px 0;
}

.input {
  margin-bottom: initial;
  input {
    min-width: 50px;
  }
  @media (max-width: 360px) {
    > p {
      font-size: 14px;
    }
  }
}

.inputError {
  input {
    border: 1px solid #ff5758 !important;
    color: #ff5758;
  }
}

.wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  background: #fff;
  z-index: 1;
  max-width: 400px;

  > div {
    display: flex;
    margin-right: 3px;

    > :not(:last-child) {
      margin-right: 3px;
    }
  }
}

.toggleMeridiem {
  display: none;
  + label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 60px;
    background: #eff2fd;
    border-radius: 8px;
    border: none;
    font-size: 18px;
    line-height: 28px;
    color: #121620;
    cursor: pointer;
  }
  &:checked + label {
    background: #066fde;
    color: #fff;
  }
}
.errorMessage {
  position: absolute;
  color: #ff5758;
  font-size: 14px;
  line-height: 18px;
  visibility: hidden;
  bottom: 20px;
  transition: all 0.5s;
}
.errorMessageShow {
  visibility: visible;
  bottom: -26px;
}

.nextButton {
  margin-top: 0px;
  transition: all 0.5s;
}

.nextButtonError {
  margin-top: 24px;
}
