.title {
  display: flex;
  background: #eff2fd;
  border-radius: 4px 4px 0px 0px;
  height: 38px;
  align-items: center;
  padding-left: 10px;

  h2 {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.1px;
  }
}
.firstReview {
  border-radius: 0px 0px 4px 4px;
}
