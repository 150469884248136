.premiumBundleWrapper {
  padding-bottom: 20px;

  h1 {
    font-weight: 600;
  }
}

.buttonSkip {
  display: flex;
  align-items: center;
  padding: 0px 8px;
  height: 30px;
  background: #eff2fd;
  border-radius: 20px;
  font-weight: 600;
  color: #066fde;
  border: none;
  margin-top: 12px;
  align-self: flex-end;
}

.title {
  font-size: 20px;
  line-height: 130%;
  text-align: center;
  margin: 8px 0;
  letter-spacing: 0.2px;
}

.subTitle {
  font-size: 16px;
  line-height: 18px;
  text-align: center;
}

.blockDescription {
  position: relative;
  background: white;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  margin: 24px 0;
  gap: 12px;

  > h1 {
    font-size: 14px;
    line-height: 140%;
    font-weight: 700;
  }
  h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  p {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
  }

  section {
    width: 100%;
    padding: 12px;
    border-top: 2px solid #e0e3eb;
    margin-bottom: -12px;

    h2 {
      color: #066fde;
    }
  }
}
.item {
  display: flex;
  align-items: center;
  align-self: flex-start;
}
.iconWrapper {
  display: flex;
  width: 40px;
  height: 40px;
  background: #ffeede;
  border-radius: 20px;
  margin-right: 16px;
  align-items: center;
  justify-content: center;
}
.buyButton {
  position: relative;
  svg {
    fill: white;
    margin-right: 8px;
  }
}
.errorWrapper {
  display: flex;
  position: relative;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  overflow: auto;
  max-height: 0;
  transition: max-height 0.5s;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
    width: 0;
    height: 0;
  }
}
.showError {
  opacity: 1;
  margin-top: 12px;
  visibility: visible;
  max-height: 78px;
}
