.singleQuestionPageContainer {
  padding-top: 0px;
}
.textWrapper {
  margin-top: 12px;
  h4 {
    text-align: center;
    line-height: 28px;
  }
}
.buttonWrapper {
  margin-top: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 12px;
  }
}
